import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
  requestVerifyEmail: ["payload"],
  successfulVerifyEmail: ["payload"],
  errorVerifyEmail: ["error"],
  requestGetClasses: ["payload"],
  successfulGetClasses: ["payload"],
  errorGetClasses: ["error"],
  requestSignUp: [],
successfulSignUp: [],
errorSignUp: [],
});

export const SignUpClassActionCreators = Creators;

const initialState = {
  loading: false,
  userInfo: {},
  error: "",
  classes: [],
  loadingSignUp: false,
};

const requestVerifyEmail = (state = initialState, action) => {
  return {
    ...state,
    loading: true,
    userInfo: {},
  };
};

const successfulVerifyEmail = (state = initialState, action) => {
  const userInfo = action.payload.result.userInfo;
  return {
    ...state,
    loading: false,
    userInfo,
  };
};

const errorVerifyEmail = (state = initialState, action) => ({
  ...state,
  loading: false,
  error: action.error,
});

const requestGetClasses = (state = initialState, action) => {
  return {
    ...state,
    loading: false,
  };
};

const successfulGetClasses = (state = initialState, action) => {
  const classes = action.payload.result.classes;
  return {
    ...state,
    loading: false,
    classes: classes.filter((cl) => cl.classSlots > 0),
  };
};

const errorGetClasses = (state = initialState, action) => ({
  ...state,
  loading: false,
  error: action.error,
});

const requestSignUp = (state = initialState, action) => {
  return {
    ...state,
    loadingSignUp: true,
  };
};

const successfulSignUp = (state = initialState, action) => {
  return {
    ...state,
    loadingSignUp: false,
  };
};

const errorSignUp = (state = initialState, action) => {
  return {
    ...state,
    loadingSignUp: false,
  };
};

export default createReducer(initialState, {
  [Types.REQUEST_VERIFY_EMAIL]: requestVerifyEmail,
  [Types.SUCCESSFUL_VERIFY_EMAIL]: successfulVerifyEmail,
  [Types.ERROR_VERIFY_EMAIL]: errorVerifyEmail,
  [Types.REQUEST_GET_CLASSES]: requestGetClasses,
  [Types.SUCCESSFUL_GET_CLASSES]: successfulGetClasses,
  [Types.ERROR_GET_CLASSES]: errorGetClasses,
  [Types.REQUEST_SIGN_UP]: requestSignUp,
  [Types.SUCCESSFUL_SIGN_UP]: successfulSignUp,
  [Types.ERROR_SIGN_UP]: errorSignUp,
});
