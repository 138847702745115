import { removeEmptyStringFieldsRecursive } from './utils/removeEmptyStringFieldsRecursive';

const defaultResponseTransformation = res => res.data;

const selectErrorMessage = err => {
	try {
		const serverError = err.response.data;
		if (serverError.message) {
			if (typeof serverError.message === 'string') {
				return serverError.message;
			}

			if (typeof serverError.message === 'object') {
				if (typeof serverError.message.message === 'string') {
					return serverError.message.message;
				}

				const actualError = serverError.message.message;
				const { children } = actualError[0];
				const { constraints } = children.length ? children[0] : actualError[0];
				const key = Object.keys(constraints)[0];

				return constraints[key];
			}
		}
	} catch (err1) {
		return 'Ha ocurrido un error inesperado, nuestros servidores podrían estar caídos... Disculpas por las molestias!';
	}

	return 'Algo salió mal';
};

/**
 * Generates a service according to the passed config object
 * @param {{request: (data: any) => Promise<any>; transformResponse?: (response: AxiosResponse<any>) => any; retry?: number; debug?: boolean}} config configuration object to define the service
 * @returns {(data: any) => Promise<any>} service you can call from your thunks
 */
export const serviceBase = ({
	request,
	transformResponse = defaultResponseTransformation,
	retry = 3,
	debug = false
}) => {
	if (!request) {
		throw Error('Please provide a valid request function to serviceBase!');
	}

	return async data => {
		if (data && typeof data === 'object') {
			removeEmptyStringFieldsRecursive(data);
		}

		try {
			if (debug) {
				console.log('Sending request...');
			}

			const response = await request(data);

			if (!response) {
				throw Error('Algo salió mal...');
			}

			return transformResponse(response);
		} catch (err) {
			if (retry > 0) {
				if (debug) {
					console.log('retrying');
				}

				return serviceBase({
					request,
					transformResponse,
					retry: retry - 1,
					debug
				})(data);
			}

			throw Error(selectErrorMessage(err));
		}
	};
};

export default serviceBase;