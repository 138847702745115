  
import { ProductsActionCreators } from '../reducers/productReducer';
import {
	getProductsService,
} from '../services/products';
// import * as MessageActions from 'app/store/actions/fuse/message.actions';

export const getProducts = data => async dispatch => {
	dispatch(ProductsActionCreators.requestProducts());
	try {
		const response = await getProductsService();
		dispatch(
			ProductsActionCreators.successfulProducts({
				result: response
			})
		);
	} catch (e) {
		// dispatch(MessageActions.showMessage({ message: e.message, variant: 'error' }));
		dispatch(ProductsActionCreators.errorProducts(e));
	}
};